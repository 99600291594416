import React, { FC, PropsWithChildren } from 'react';

import { Box } from '@sparky';
import { BoxProps } from '@sparky/types';

import { swipableBoxStyle } from './SwipableBox.css';

/* This Box can be scrolled horizontally but it does not show the scrollbar */
export const SwipableBox: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box className={swipableBoxStyle} {...props}>
      {children}
    </Box>
  );
};
